var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: _vm.$t('label.incidence'),
          placement: 'top-end'
        }),expression:"{\n          content: $t('label.incidence'),\n          placement: 'top-end'\n        }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleCreate}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nueva'))+" ")],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedIncidenceList,"fields":_vm.fields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loading,"sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"cell-center text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.edit')+' '+_vm.$t('label.incidence'),
            placement: 'top-end'
          }),expression:"{\n            content: $t('label.edit')+' '+$t('label.incidence'),\n            placement: 'top-end'\n          }"}],staticClass:"d-flex align-items-center",attrs:{"size":"sm","square":"","color":"edit"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])}),_c('modal-incidence',{attrs:{"modal":_vm.modalActive,"isEdit":_vm.isEdit,"IncidenceItem":_vm.IncidenceItem},on:{"update:modal":function($event){_vm.modalActive=$event},"Update-list":_vm.Update,"close":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }